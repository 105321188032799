import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/mdx.js";
import FounderApplicationForm from "src/components/founder-application-form";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageHeading = makeShortcode("PageHeading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeading title={props.pageContext.frontmatter.title} image="sketch" crumbs={["TechSlice", "Entrepreneurs", props.pageContext.frontmatter.title]} mdxType="PageHeading"></PageHeading>
    <div className="container" style={{
      maxWidth: 800,
      marginBottom: "4rem"
    }}>
      <p>{`All submitted information will be kept in strict confidence, and is only used to help us find the best fit for you and for the investors in our network. All answers are optional.`}</p>
      <FounderApplicationForm mdxType="FounderApplicationForm"></FounderApplicationForm>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      